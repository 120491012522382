import React from 'react'
import {graphql } from 'gatsby'
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import * as styles from './article.module.css'

class ArticleTemplate extends React.Component {
  render() {
    const article = get(this.props, 'data.contentfulArticle')

    return (
      <Layout location={this.props.location}>
        <Seo
          title={article.title}
          description={article.title}
          image=""
        />
        <Hero
          image={article.heroImage?.gatsbyImageData}
          title={article.title}
          content=""
        />
        <div className={styles.container}>
          <span className={styles.meta}>
            <time dateTime={article.rawDate}>{article.updatedAt}</time> –{' '}
          </span>
          <div className={styles.article}>
            <div className={styles.body}>
              {article.body?.raw && renderRichText(article.body)}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ArticleTemplate

export const pageQuery = graphql`
  query ArticleBySlug(
    $slug: String!
  ) {
    contentfulArticle(slug: { eq: $slug }) {
      slug
      title
      updatedAt(formatString: "MMMM Do, YYYY")
      rawDate: updatedAt
      body {
        raw
      }
    }
  }
`
